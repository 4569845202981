<template lang="">
    <div></div>
</template>

<script>
import * as AuthAPI from '../../api/authAPI';
import { mixinLoadings, mixinToasts } from '../../mixins';
import { checkIsManager, checkIsMaster, isMobile } from '../../utils';

export default {
    mixins: [mixinLoadings, mixinToasts],
    async created() {
        await this.onLoginByMS365();
    },
    methods: {
        handleNavigate(userInfo) {
            if (isMobile()) {
                this.$router.push('/my-page');
            } else {
                if (checkIsMaster(userInfo) || checkIsManager(userInfo)) {
                    this.$router.push('/dashboard');
                } else {
                    this.$router.push('/my-page');
                }
            }
        },

        async onLoginByMS365() {
            const companyCode = sessionStorage.getItem('companyCode');
            try {
                this.showLoading();
                const { code, client_info } = this.$route.query;
                const body = { company_code: companyCode, code, client_info };
                const res = await AuthAPI.loginBy365Redirect(body);
                const userInfo = {
                    ...res?.results?.object,
                    company_code: companyCode,
                };
                this.$store.dispatch('login', userInfo);
                this.handleNavigate(userInfo?.result);
                sessionStorage.removeItem('companyCode');
            } catch (error) {
                this.$router.push(`/sign-in/${sessionStorage.getItem('companyCode')}`);
                this.showError(error);
            } finally {
                this.hideLoading();
            }
        },
    },
};
</script>

<style lang=""></style>
